import { http } from '@/http/axios.js'
export function getInfoByType(type) {
  return http({
    url: '/company/download/member/getInfoByType',
    method: 'get',
    params: { type }
  })
}
export function createOrUpdate(data) {
  return http({
    url: '/company/download/member/createOrUpdate',
    method: 'post',
    data
  })
}
