<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="lease-content">
      <div class="left">
        <el-tabs v-model="activeName" tab-position="left" style="height: 200px" @tab-click="handleClick">
          <el-tab-pane label="单文件下载配置" name="1"></el-tab-pane>
          <el-tab-pane label="会员下载配置" name="2"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="right">
        <div class="title">{{ title }}</div>
        <p style="margin-top: 15px;" >标题：</p>
        <el-input size="small" :showBtn="true"  v-model="formData.title" placeholder="标题"></el-input>
        <p style="margin-top: 15px;" >是否开启：</p>
        <el-radio-group v-model="formData.status">
          <el-radio :label="1">开启</el-radio>
          <el-radio :label="0">关闭</el-radio>
        </el-radio-group>
        <p style="margin-top: 15px;" >金额：</p>
        <el-input-number :showBtn="true"  v-model="formData.money" :min="0" label="金额"></el-input-number>
        <p  style="margin-top: 15px;">描述：</p>
        <el-input type="textarea" rows="3" :showBtn="true"  placeholder="请输入内容" v-model="formData.describe" maxlength="500" show-word-limit> </el-input>
        <div style="margin-top: 20px">
          <el-button type="primary" size="small" @click="save">保存</el-button>
          <el-button type="" size="small" @click="cancel">取消</el-button>
        </div>
      </div>
    </div>
    <div style="height: 40px"></div>
  </div>
</template>

<script>
import { getInfoByType, createOrUpdate } from './api'

export default {
  name: 'List',
  components: { },
  data() {
    return {
      activeName: '1',
      formData: {
        title: '',
        money: 0,
        status: 1,
        describe: '',
      },
    }
  },
  watch: {
    activeName: {
      immediate: true,
      handler(newV, oldV) {
        this.getDetail()
      }
    }
  },
  mounted() {},
  computed: {
    title() {
      let name = ''
      if (this.activeName == '1') {
        name = '单文件下载配置'
      } else if (this.activeName == '2') {
        name = '会员下载配置'
      }
      return name
    }
  },
  methods: {
    async getDetail() {
      const res = await getInfoByType(this.activeName)
      this.formData = res
      if (res === null) {
        this.formData = {
        title: '',
        money: 0,
        status: 1,
        describe: '',
        }
      } else {
        this.formData = res
      }
    },
    handleClick(tab, event) {
      console.log(tab, event)
    },
    save() {
      let data = { type: this.activeName, money: this.formData.money, describe: this.formData.describe, title: this.formData.title, status: this.formData.status}

      createOrUpdate(data).then(() => {
        this.$message.success('保存成功')
        this.getDetail()
      })
    },
    cancel() {
      this.getDetail()
      // 初始化编辑器
      this.$refs.editor.init()
    }
    // handlerArtSuccess(file) {
    //   console.log(file)
    //   let reader = new FileReader()
    //   reader.readAsArrayBuffer(file.raw)
    //   reader.onload = e => {
    //     const data = e.target.result
    //     mammoth.convertToHtml({ arrayBuffer: data }).then(res => {
    //       this.displayResult(res.value)
    //     })
    //   }
    // },
    // displayResult(html) {
    //   let newHtml = html
    //     .replace('<h1>', '<h1 style="text-align:center;">')
    //     .replace('<h2>', '<h2 style="text-align:center;">')
    //     .replace('<img', '<img style="width:20%;"')
    //     .replace(/<table>/g, '<table style="border-collapse:collapse;">')
    //     .replace(/<tr>/g, '<tr style="height:30px;">')
    //     .replace(/<td>/g, '<td style="border:1px solid #ccc">')
    //     .replace(/<p>/g, '<p style="text-indent:2em;">')
    //   this.content = newHtml
    //   this.$refs.editor.init()
    // }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .lease-content {
    display: flex;
    .left {
      width: 150px;
      .el-tabs /deep/ {
        .el-tabs__nav-wrap.is-left::after {
          width: 0;
        }
      }
    }
    .right {
      flex: 1;
      .title {
        margin-bottom: 10px;
        margin-top: 8px;
        font-weight: 500;
        font-size: 15px;
      }
    }
  }
}
</style>
